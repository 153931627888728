/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuctionDto } from '../models/auction-dto';
import { AuctionDataDto } from '../models/auction-data-dto';
import { AuctionOptionDto } from '../models/auction-option-dto';
import { AuctionStatsDto } from '../models/auction-stats-dto';
import { FindAuctionsResponseDto } from '../models/find-auctions-response-dto';
import { Operation } from '../models/operation';

@Injectable({
  providedIn: 'root',
})
export class GeneratedAuctionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAuctions
   */
  static readonly GetAuctionsPath = '/api/auctions';

  /**
   * Gets all auctions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuctions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuctions$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FindAuctionsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionService.GetAuctionsPath, 'get');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FindAuctionsResponseDto>;
      })
    );
  }

  /**
   * Gets all auctions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuctions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuctions(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<FindAuctionsResponseDto> {

    return this.getAuctions$Response(params,context).pipe(
      map((r: StrictHttpResponse<FindAuctionsResponseDto>) => r.body as FindAuctionsResponseDto)
    );
  }

  /**
   * Path part for operation createAuction
   */
  static readonly CreateAuctionPath = '/api/auctions';

  /**
   * Creates an auction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAuction()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAuction$Response(params: {
    'X-API-Version'?: string;
    body: AuctionDataDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuctionDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionService.CreateAuctionPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuctionDto>;
      })
    );
  }

  /**
   * Creates an auction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAuction$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAuction(params: {
    'X-API-Version'?: string;
    body: AuctionDataDto
  },
  context?: HttpContext

): Observable<AuctionDto> {

    return this.createAuction$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuctionDto>) => r.body as AuctionDto)
    );
  }

  /**
   * Path part for operation getAuction
   */
  static readonly GetAuctionPath = '/api/auctions/{id}';

  /**
   * Get an auction by its id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuction()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuction$Response(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuctionDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionService.GetAuctionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuctionDto>;
      })
    );
  }

  /**
   * Get an auction by its id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuction(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<AuctionDto> {

    return this.getAuction$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuctionDto>) => r.body as AuctionDto)
    );
  }

  /**
   * Path part for operation deleteAuction
   */
  static readonly DeleteAuctionPath = '/api/auctions/{id}';

  /**
   * Deletes an auction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAuction()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAuction$Response(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionService.DeleteAuctionPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes an auction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAuction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAuction(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteAuction$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchAuction
   */
  static readonly PatchAuctionPath = '/api/auctions/{id}';

  /**
   * Patches an auction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAuction()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAuction$Response(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuctionDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionService.PatchAuctionPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuctionDto>;
      })
    );
  }

  /**
   * Patches an auction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchAuction$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAuction(params: {
    id: number;
    'X-API-Version'?: string;
    body: Array<Operation>
  },
  context?: HttpContext

): Observable<AuctionDto> {

    return this.patchAuction$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuctionDto>) => r.body as AuctionDto)
    );
  }

  /**
   * Path part for operation getAuctionStats
   */
  static readonly GetAuctionStatsPath = '/api/auctions/{id}/stats';

  /**
   * Get an auction by its id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuctionStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuctionStats$Response(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuctionStatsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionService.GetAuctionStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuctionStatsDto>;
      })
    );
  }

  /**
   * Get an auction by its id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuctionStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuctionStats(params: {
    id: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<AuctionStatsDto> {

    return this.getAuctionStats$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuctionStatsDto>) => r.body as AuctionStatsDto)
    );
  }

  /**
   * Path part for operation getAuctionOptions
   */
  static readonly GetAuctionOptionsPath = '/api/auctions/options';

  /**
   * Gets all auctions as simple id / title tuples.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuctionOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuctionOptions$Response(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AuctionOptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionService.GetAuctionOptionsPath, 'get');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AuctionOptionDto>>;
      })
    );
  }

  /**
   * Gets all auctions as simple id / title tuples.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuctionOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuctionOptions(params?: {
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<Array<AuctionOptionDto>> {

    return this.getAuctionOptions$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AuctionOptionDto>>) => r.body as Array<AuctionOptionDto>)
    );
  }

  /**
   * Path part for operation sendSummaryMail
   */
  static readonly SendSummaryMailPath = '/api/auctions/{auctionId}/send-summary-mail';

  /**
   * Sends summary mails for customers / bidders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendSummaryMail()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendSummaryMail$Response(params: {
    auctionId: number;
    onlyUnsentParts?: boolean;
    sendExampleForUserId?: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAuctionService.SendSummaryMailPath, 'post');
    if (params) {
      rb.path('auctionId', params.auctionId, {});
      rb.query('onlyUnsentParts', params.onlyUnsentParts, {});
      rb.query('sendExampleForUserId', params.sendExampleForUserId, {});
      rb.header('X-API-Version', params['X-API-Version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sends summary mails for customers / bidders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendSummaryMail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendSummaryMail(params: {
    auctionId: number;
    onlyUnsentParts?: boolean;
    sendExampleForUserId?: number;
    'X-API-Version'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.sendSummaryMail$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
